import { Box, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { Button } from './Button'

export interface UIDialogRef {
  show: (a?: any) => void
  hide: () => void
}

interface Props extends Omit<DialogProps, 'open'> {
  label: string
  actions?: React.JSX.Element
  onCancel?: () => void
  onSubmit?: () => void
  onClose?: () => void
  onReset?: () => void
  submit_title?: string

  styleContent?: any
  children: React.JSX.Element
}
export const DialogMain = forwardRef<UIDialogRef, Props>(
  ({ label: title, actions, onReset, onCancel, onSubmit, submit_title, onClose, children, styleContent, ...others }, ref) => {
    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [open, setOpen] = useState<boolean>(false)
    useImperativeHandle(
      ref,
      () => ({
        show: () => {
          setOpen(true)
        },
        hide: () => {
          setOpen(false)
        }
      }),
      []
    )
    return (
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        open={open}
        {...others}
        sx={{
          '& *': {
            fontFamily: 'Be Vietnam Pro, sans-serif',
            fontStyle: 'normal'
          },
          '& .MuiDialog-container': {
            height: 'calc(100vh - -28px)',
            marginTop: '-8px',
            alignItems: 'flex-start'
          },
          '& .MuiDialog-paper': {
            // backgroundColor: 'rgba(255, 255, 255, 1e-05)',
            borderRadius: '8px'
          }
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ fontWeight: 'bold', p: '16px 20px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography
              style={{
                fontWeight: 'bold'
              }}
            >
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1055_475061)">
                  <rect width="20" height="20" fill="white" fillOpacity="0.01" />
                  <path
                    d="M5.72719 4.84331C5.48559 4.60171 5.0849 4.60171 4.84331 4.84331C4.60171 5.0849 4.60171 5.48559 4.84331 5.72719L9.11541 9.99929L4.84331 14.2714C4.60171 14.513 4.60171 14.9137 4.84331 15.1553C5.0849 15.3969 5.48559 15.3969 5.72719 15.1553L9.99929 10.8832L14.2714 15.1553C14.513 15.3969 14.9137 15.3969 15.1553 15.1553C15.3969 14.9137 15.3969 14.513 15.1553 14.2714L10.8832 9.99929L15.1553 5.72719C15.3969 5.48559 15.3969 5.0849 15.1553 4.84331C14.9137 4.60171 14.513 4.60171 14.2714 4.84331L9.99929 9.11541L5.72719 4.84331Z"
                    fill="#999999"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1055_475061">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            width: '800px',
            maxHeight: '70vh',
            ...styleContent,
            borderTop: '1px solid #EEEEEE',
            borderBottom: '1px solid #EEEEEE',
            padding: '16px 20px'
          }}
        >
          <Box>{children}</Box>
        </DialogContent>
        <DialogActions>
          {actions || (
            <Stack direction="row" spacing={1}>
              {onReset && <Button onClick={onReset} type="default" label="Thiết lập lại" />}
              {onCancel && <Button onClick={onCancel} type="default" label="Hủy bỏ" />}
              {onSubmit && <Button onClick={onSubmit} type="primary" label={submit_title ?? 'Áp dụng'} />}
            </Stack>
          )}
        </DialogActions>
      </Dialog>
    )
  }
)
