/* eslint-disable @typescript-eslint/indent */
import React, { RefObject, createRef, forwardRef, useImperativeHandle, useState } from 'react'
import { DialogMain, UIDialogRef } from './DialogMain'
import Api, { ApiRequest } from 'services'
import { CommentEntity } from 'models/comment'
import { showApiError } from './GlobalMessage'
import { Avatar, Button, Stack, Typography } from '@mui/material'
import { timeSince } from 'utils'
import _ from 'lodash'
import { Rating } from 'react-simple-star-rating'

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
export const commentsPopupRef = React.createRef<any>()
export const commentsPopup = {
  show: (params: ApiRequest.comment.index) => {
    commentsPopupRef?.current?.show(params)
  },
  hide: () => {
    commentsPopupRef?.current?.hide()
  }
}

const CommentItem = ({ data }: { data: CommentEntity }) => {
  const { created_by, created_at, content, score } = data
  return (
    <Stack
      direction={'row'}
      sx={{
        py: 1,
        borderBottomWidth: '1px',
        borderBottomColor: '#737373'
      }}
    >
      <Avatar src={created_by?.avatar} />
      <Stack direction={'column'} sx={{ display: 'flex', flex: 1, ml: 1.5 }}>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography style={{ fontSize: '14px', fontWeight: '500', color: '#000' }}>{created_by?.name ?? ''}</Typography>
          <Stack direction={'row'} alignItems={'center'}>
            <Typography
              style={{
                fontSize: '12px',
                color: '#000',
                marginRight: '4px',
                marginTop: '10px'
              }}
            >{`${score}/10`}</Typography>
            <Rating rtl SVGstyle={{ display: 'inline' }} size={16} readonly iconsCount={10} initialValue={score} />
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#737373' }}>{created_by?.position ?? ''}</Typography>
          <Typography style={{ fontSize: '12px', fontWeight: '400', color: '#737373' }}>{timeSince(new Date(created_at))}</Typography>
        </Stack>
        <Typography style={{ fontSize: '13px', fontWeight: '400', color: '#333', marginTop: '6px' }}>{`${content}`}</Typography>
      </Stack>
    </Stack>
  )
}
export const CommentsPopup = forwardRef((props, ref) => {
  const [data, setData] = useState<CommentEntity[]>([])
  const [asc, setAsc] = useState<boolean>(false)
  const dialogRef: RefObject<UIDialogRef> = createRef()
  useImperativeHandle(ref, () => {
    return { show, hide }
  })

  const show = (params: ApiRequest.comment.index) => {
    Api.comment
      .index(params)
      .then((res: any) => {
        console.log('Get comment: ', res)
        setData(res?.data?.data?.data ?? [])
      })
      .catch((e) => {
        setData([])
        showApiError(e)
      })
    dialogRef?.current?.show()
  }
  const hide = () => {
    dialogRef?.current?.hide()
  }
  const repeatArray = (arr: any[], count: number) => {
    return _.flatten(new Array(count).fill(0).map((i) => arr))
  }
  const displayData = [...data]
  displayData.sort((u, v) => {
    if (asc) {
      return u.score < v.score ? -1 : 1
    }
    return u.score > v.score ? -1 : 1
  })
  return (
    <DialogMain ref={dialogRef} label={'Danh sách đánh giá '} onClose={hide}>
      <Stack>
        <div style={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={() => setAsc(!asc)}
            component="label"
            role={undefined}
            variant="outlined"
            endIcon={!asc ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
          >
            Sắp xếp đánh giá
          </Button>
        </div>
        <Stack
          direction={'column'}
          sx={{
            mx: 5
          }}
        >
          {displayData.map((item) => (
            <CommentItem data={item} />
          ))}
        </Stack>
      </Stack>
    </DialogMain>
  )
})
