import { AttachmentEntity } from './attachment'
import { CategoryEntity } from './category'
import { CollectionEntity } from './collection'
import { CommentEntity } from './comment'
import { EntityBase } from './entity'
export interface CommonProductEntity extends EntityBase {
  categories?: CategoryEntity[]
  collections?: CollectionEntity[]
  sku: string
  campaigns: any
  design_code: string
  created_at: string
  images: AttachmentEntity[]
  is_variant: boolean
  name: string
  updated_at: string
  comments?: CommentEntity[]
  price: number
  rating_count: number
  rating_sum: number
  gender: EnumGender
}

export interface VariantEntity extends CommonProductEntity {
  color?: string
  size?: string
  parent?: ProductEntity
  variant_index: string
}

export interface ProductEntity extends CommonProductEntity {
  material: string
  description: string
  children: VariantEntity[]
  count_unit: string
  price_range: string
  order_day_count: string
  brand_name: string
  material_source: string
  estimated_quantity: string
  highlights: string
  color_category: string
  design_description: string
  ingredient: string
}
export enum EnumGender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNISEX = 'UNISEX',
  GIRL_CHILD = 'GILD_CHILD',
  BOY_CHILD = 'BOY_CHILD',
  CHILD = 'CHILD'
}

export const GenderLabelType: Record<EnumGender, string> = {
  [EnumGender.FEMALE]: 'Nữ',
  [EnumGender.MALE]: 'Nam',
  [EnumGender.UNISEX]: 'Unisex',
  [EnumGender.BOY_CHILD]: 'Bé trai',
  [EnumGender.GIRL_CHILD]: 'Bé gái',
  [EnumGender.CHILD]: 'Trẻ em'
}

export const ProductFields: ProductFieldKey[] = [
  //
  'categories',
  //
  'gender',
  'sku',
  'name',
  //
  'count_unit',
  'price',
  'price_range',
  //
  'design_code',
  'color',
  'color_category',
  //
  'size',
  'material',
  'ingredient',
  //
  'order_day_count',
  'material_source',
  'estimated_quantity',
  //
  'highlights',
  'design_description',
  'description',
  //
  'brand_name'
]
export type ProductFieldKey = keyof ProductEntity | keyof VariantEntity
