import { EnumGender } from 'models/product'

export enum EnumProductimportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportProductState {
  file?: File
  is_preview?: boolean
  data?: ProductImportEntity[]
  category?: EnumProductimportCategory
}

export interface ProductImportEntity {
  index: number
  sku: string
  design_code: string
  name: string
  color: string
  size: string
  price: number
  description: string
  highlights: string
  categories: string[]
  is_variant: boolean
  gender: EnumGender
  material: string
  //
  variant_index: string
  ingredient: string
  design_description: string
  count_unit: string
  price_range: string
  order_day_count: string
  material_source: string
  estimated_quantity: number
  color_category: String

  //
  rating_count: number
  rating_sum: number
  category_names: string[]
  parent_sku: string
  parent_design_code: string
  variant_design_code: string
  collection_name: string
  collection_id?: string
  category_ids?: string[]
}
