/* eslint-disable no-confusing-arrow */
import { TableColumn } from 'models/table'
import TableArea from './area'
import TableCampaign from './campaign'
import TableOrder from './order'
import TableProduct from './product'
import TableLocation from './location'
import TableRole from './role'
import TableRoleUser from './role_user'
import TableUser from './user'
export const TABLE_COLUMNS: Record<string, TableColumn<any>[]> = {
  CAMPAIGN: TableCampaign,
  PRODUCT: TableProduct,
  ORDER_LIST: TableOrder,
  LOCATION: TableLocation,
  AREA: TableArea,
  ROLE: TableRole,
  USER_PORTAL: TableUser,
  GROUP_PERMISSION_USER: TableRoleUser
}
