/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProfileStateEntity, StoreEntity, UserEntity } from 'models'

const initialState: ProfileStateEntity = {}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (state, { payload }: PayloadAction<UserEntity>) => {
      state.user = payload
    }
  }
})
export const { actions: profileActions, reducer: profileReducer } = slice
