/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { CampaignEntity, CampaignStateEntity, CategoryEntity, EnumCampaignTab, OrderEntity, OrderUnitType, OrgUnitEntity } from 'models'
import { ApiRequest } from 'services'

const getOrderQuery = (state: CampaignStateEntity) => {
  const params: Partial<ApiRequest.order.index> = {
    campaign: state.campaign?.id,
    all: true
  }
  if (state.order_unit_type === 'overall') {
    params.is_overall_order = true
  } else if (state.order_unit_type === 'area') {
    params.area_unit = state.order_unit?.id
  } else if (state.order_unit_type === 'store') {
    params.store_unit = state.order_unit?.id
  }
  return params
}

const initialState: CampaignStateEntity = {
  tab: EnumCampaignTab.PRODUCTS,
  orders: [],
  order_param: {}
}
const slice = createSlice({
  name: 'CAMPAIGN_STATE',
  initialState,
  reducers: {
    setTab: (state, { payload }: PayloadAction<EnumCampaignTab>) => {
      state.tab = payload
    },
    setCampaign: (state, { payload }: PayloadAction<CampaignEntity>) => {
      state.campaign = { ...payload }
      state.orders = []
      state.category_id = undefined
      state.in_cart = false
      state.order_unit_type = undefined
      state.order_param = getOrderQuery(state)
    },
    updateCampaign: (state, { payload }: PayloadAction<Partial<CampaignEntity>>) => {
      if (!state.campaign) {
        return
      }
      if (state.campaign) {
        state.campaign = {
          ...state.campaign,
          ...payload
        }
      }
    },
    setCategories: (state, { payload }: PayloadAction<CategoryEntity[]>) => {
      state.categories = payload
    },
    selectCategory: (state, { payload }: PayloadAction<string | undefined>) => {
      state.category_id = payload
    },
    setInCart: (state, { payload }: PayloadAction<boolean>) => {
      state.in_cart = payload
    },
    setOrderUnit: (state, { payload }: PayloadAction<{ type?: OrderUnitType; unit?: OrgUnitEntity }>) => {
      state.order_unit = payload.unit
      state.order_unit_type = payload.type
      state.order_param = getOrderQuery(state)
    },
    selectOrderImportFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.order_import_file = payload
    },
    setOrders: (state, { payload }: PayloadAction<OrderEntity[]>) => {
      state.orders = payload
    }
  }
})
export const { actions: campaignActions, reducer: campaignReducer } = slice
