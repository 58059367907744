/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ColorEntity, EnumImportCategory, ImportColorState } from 'models'
const initialState: ImportColorState = {
  all_data: [],
  category: EnumImportCategory.ALL
}
const slice = createSlice({
  name: 'IMPORT_COLOR_STATE',
  initialState,
  reducers: {
    setFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.file = payload
      if (payload === undefined) {
        state.is_preview = false
      }
    },
    setPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.is_preview = payload
    },
    selectCategory: (state, { payload }: PayloadAction<EnumImportCategory>) => {
      state.category = payload
    },
    setImportedData: (state, { payload }: PayloadAction<ColorEntity[]>) => {
      state.imported_data = payload
    },
    setAllData: (state, { payload }: PayloadAction<ColorEntity[]>) => {
      state.all_data = payload
    }
  }
})
export const { actions: importColorActions, reducer: importColorReducer } = slice
