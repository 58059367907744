import { ListEntity, ProductEntity } from 'models'
import { instanceAxios, transformObjToUrlQuery } from 'utils'
import { ApiRequest } from './interface'

const index = async (params: ApiRequest.product.index): Promise<ListEntity<ProductEntity>> => instanceAxios.get(`/products${transformObjToUrlQuery(params)}`)

export const geetDetailProduct = async (id: string) => instanceAxios.get(`/products/${id}`)
export const updateProduct = async (id: string, data: ApiRequest.product.update) => instanceAxios.patch(`/products/${id}`, data)

export default {
  index
}
