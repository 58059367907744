import { CampaignEntity, EnumCampaignStatus, EnumCampaignType, Response } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'

const remove = (id: string) => instanceAxios.delete(`/campaigns/${id}`)

const setStatus = (id: string, status: EnumCampaignStatus) => instanceAxios.patch(`/campaigns/${id}/set-status`, { status })
const update = (id: string, data: ApiRequest.campaign.update) => instanceAxios.patch(`/campaigns/${id}`, data)
const show = (id: string): Promise<any> => instanceAxios.get(`/campaigns/${id}`)
const guides = () => instanceAxios.get(`/campaigns/guides`)
const getAreas = (type: EnumCampaignType): Promise<any> => instanceAxios.get(`/campaigns/areas/${type}`)
const updateHighlights = (id: string, products: string[]) => instanceAxios.patch(`/campaigns/${id}/highlight-products`, { products })
export default {
  update,
  show,
  guides,
  setStatus,
  remove,
  getAreas,
  updateHighlights
}
