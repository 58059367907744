import React from 'react'
import { useDispatch } from 'react-redux'
import Api from 'services'
import { profileActions } from 'store'
import { CookiesCustom } from 'utils/cookies'

function useAppInit(setLoading: React.Dispatch<React.SetStateAction<boolean>>) {
  const dispatch = useDispatch()
  const init = async () => {
    const token = await CookiesCustom.getToken()
    if (token) {
      Api.me
        .show()
        .then((res: any) => {
          setLoading(false)
          dispatch(profileActions.setProfile(res.data.data))
        })
        .catch(() => {
          setLoading(false)
        })
    } else {
      setLoading(false)
    }
  }
  init()
}

export { useAppInit }
