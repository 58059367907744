import { EntityBase } from 'models/entity'
import { EnumModule } from './module'

export interface PermissionEntity extends EntityBase {
  module: EnumModule
  action: EnumAction
  label?: string
}

export enum EnumAction {
  CREATE = 'create',
  UPDATE = 'update',
  VIEW = 'view',
  DELETE = 'delete',
  SYNC_PORTAL = 'sync_portal',
  IMPORT_EXCEL = 'import_excel',
  ORDER_COMPANY_VIEW = 'order_company_view',
  ORDER_COMPANY_CREATE = ' order_company_create',
  ORDER_AREA_VIEW = 'order_area_view',
  ORDER_AREA_CREATE = 'order_area_create',
  ORDER_STORE_VIEW = 'order_store_view',
  ORDER_STORE_CREATE = 'order_store_create'
}

export const ALL_PERMISSIONS: Partial<PermissionEntity>[] = [
  // Module Trienex
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.VIEW,
    label: 'Xem danh sách/chi tiết'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.UPDATE,
    label: 'Cập nhật thông tin'
  },
  {
    module: EnumModule.CAMPAIGN,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  // Module Sản phẩm
  {
    module: EnumModule.PRODUCT,
    action: EnumAction.VIEW,
    label: 'Xem danh sách/ chi tiết'
  },
  {
    module: EnumModule.PRODUCT,
    action: EnumAction.IMPORT_EXCEL,
    label: 'Nhập excel'
  },
  {
    module: EnumModule.PRODUCT,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.PRODUCT,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  {
    module: EnumModule.PRODUCT,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  // Module Đơn hàng
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_COMPANY_VIEW,
    label: 'Xem danh sách đơn hàng phạm vi công ty'
  },
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_COMPANY_CREATE,
    label: 'Tạo đơn hàng phạm vi công ty'
  },
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_AREA_VIEW,
    label: 'Xem danh sách đơn hàng phạm vi vùng'
  },
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_AREA_CREATE,
    label: 'Tạo đơn hàng phạm vi vùng'
  },
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_STORE_VIEW,
    label: 'Xem danh sách đơn hàng phạm vi cửa hàng'
  },
  {
    module: EnumModule.ORDER,
    action: EnumAction.ORDER_STORE_CREATE,
    label: 'Tạo đơn hàng phạm vi cửaa hàng'
  },
  // Module Đánh giá sản phẩm
  {
    module: EnumModule.COMMENT,
    action: EnumAction.VIEW,
    label: 'Xem danh sách/chi tiết'
  },
  {
    module: EnumModule.COMMENT,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.COMMENT,
    action: EnumAction.UPDATE,
    label: 'Cập nhật thông tin'
  },
  {
    module: EnumModule.COMMENT,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  // Module Danh mục sản phẩm
  {
    module: EnumModule.CATEGORY,
    action: EnumAction.VIEW,
    label: 'Xem danh sách'
  },
  {
    module: EnumModule.CATEGORY,
    action: EnumAction.IMPORT_EXCEL,
    label: 'Nhập excel'
  },
  // Module Phân quyền
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.CREATE,
    label: 'Tạo mới'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.VIEW,
    label: 'Xem'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.DELETE,
    label: 'Xoá'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    action: EnumAction.UPDATE,
    label: 'Cập nhật'
  },
  // Module Phân vùng
  {
    module: EnumModule.AREA,
    action: EnumAction.VIEW,
    label: 'Xem thông tin'
  },
  {
    module: EnumModule.AREA,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Sơ đồ tổ chức
  {
    module: EnumModule.ORG_UNIT,
    action: EnumAction.VIEW,
    label: 'Xem thông tin'
  },
  {
    module: EnumModule.ORG_UNIT,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Vị trí
  {
    module: EnumModule.LOCATION,
    action: EnumAction.VIEW,
    label: 'Xem thông tin'
  },
  {
    module: EnumModule.LOCATION,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  },
  // Module Nhân viên
  {
    module: EnumModule.USER,
    action: EnumAction.VIEW,
    label: 'Xem thông tin'
  },
  {
    module: EnumModule.USER,
    action: EnumAction.SYNC_PORTAL,
    label: 'Đồng bộ portal'
  }
]
