import { instanceAxios, transformObjToUrlQuery } from 'utils'
import { ApiRequest } from './interface'

const index = (data: ApiRequest.order.index) => instanceAxios.get(`/orders${transformObjToUrlQuery(data)}`)

const create = (data: ApiRequest.order.create) => instanceAxios.post(`/orders`, data)

const update = (id: string, data: ApiRequest.order.update) => instanceAxios.patch(`/orders/${id}`, data)

const del = async (id: string, campaign_id: string): Promise<any> => instanceAxios.delete(`/orders/${id}`)
export default {
  index,
  create,
  update,
  del
}
