import { OrderEntity, TableColumn } from 'models'

const Table: TableColumn<OrderEntity>[] = [
  {
    header: {
      label: 'Mã hàng'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.sku,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Tên'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.name,
      width: 300,
      align: 'left'
    }
  },
  {
    header: {
      label: 'Màu'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.color ?? '-',
      width: 140
    }
  },
  {
    header: {
      label: 'Size'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.size ?? '-',
      width: 80
    }
  },
  {
    header: {
      label: 'Giá bán'
    },
    cell: {
      getValue: (a: OrderEntity) => a.product?.price?.toLocaleString(),
      width: 80
    }
  },
  {
    header: {
      label: 'Số lượng'
    },
    cell: {
      getValue: (a: OrderEntity) => a.quantity.toLocaleString(),
      width: 80
    }
  },
  {
    header: {
      label: 'Tổng tiền'
    },
    cell: {
      getValue: (a: OrderEntity) => (a?.quantity * a.product?.price).toLocaleString('vi-VN'),
      width: 120
    }
  },
  {
    header: {
      label: 'Đơn vị đặt'
    },
    cell: {
      getValue: (a: OrderEntity) =>
        a.is_overall_order ? 'Merchandise' : a.area_unit?.mapping_area?.name ?? a.store_unit?.mapping_store?.name ?? 'Không có thông tin'
    }
  }
]
export default Table
