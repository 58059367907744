import { CssBaseline, ThemeProvider } from '@mui/material'
import React, { lazy, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { Loadable, ProtectedAuth } from 'components'
import { GroupPermissionRoutes } from 'components/GroupPermission'
import { BASE_THEME } from 'constant'
import { useAppInit } from 'hooks'
import { MenuUrl } from 'models'
import { persistor, store } from 'store'

const PageNotFound = Loadable(lazy(() => import('pages/404')))
const MainLayout = Loadable(lazy(() => import('layout/MainLayout')))

const CampaignList = Loadable(lazy(() => import('pages/campaign/list')))
const CampaignEdit = Loadable(lazy(() => import('pages/campaign/edit')))
const StoreList = Loadable(lazy(() => import('pages/location/list')))
const OrgUnitList = Loadable(lazy(() => import('pages/org_unit/list')))
const AreaList = Loadable(lazy(() => import('pages/area/list')))

const UserList = Loadable(lazy(() => import('pages/user_portal/list')))

function AppRoutes() {
  const [loading, setLoading] = useState<boolean>(true)
  useAppInit(setLoading)

  return (
    !loading && (
      <BrowserRouter>
        <Routes>
          <Route path="/404" element={<PageNotFound />} />
          <Route
            path="/"
            element={
              <ProtectedAuth>
                <MainLayout />
              </ProtectedAuth>
            }
          >
            <Route path={MenuUrl.campaign} element={<CampaignList />} />
            <Route path={`${MenuUrl.campaign}/detail/:id`} element={<CampaignEdit />} />
            <Route path={`${MenuUrl.campaign}/create`} element={<CampaignEdit />} />
            <Route path={MenuUrl.user_portal} element={<UserList />} />
            <Route path={MenuUrl.org_unit} element={<OrgUnitList />} />

            {GroupPermissionRoutes.map(({ element, path }) => (
              <Route path={path} element={element} />
            ))}

            <Route path={MenuUrl.location} element={<StoreList />} />

            <Route path={MenuUrl.area} element={<AreaList />} />
          </Route>

          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </BrowserRouter>
    )
  )
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={BASE_THEME}>
          <CssBaseline />
          <AppRoutes />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
