export interface ModuleEntity {
  module: EnumModule
  label: string
}

export enum EnumModule {
  GROUP_PERMISSION = 'group_permission',
  CAMPAIGN = 'campaign',
  PRODUCT = 'product',
  ORDER = 'order',
  AREA = 'area',
  COMMENT = 'comment',
  ORG_UNIT = 'org_unit',
  CATEGORY = 'category',
  CUSTOMER = 'customer',
  LOCATION = 'location',
  USER = 'user'
}
export const ALL_MODULES: ModuleEntity[] = [
  {
    module: EnumModule.CAMPAIGN,
    label: 'Triển lãm'
  },
  {
    module: EnumModule.PRODUCT,
    label: 'Sản phẩm'
  },
  {
    module: EnumModule.ORDER,
    label: 'Đơn hàng'
  },
  {
    module: EnumModule.COMMENT,
    label: 'Đánh giá sản phẩm'
  },
  {
    module: EnumModule.CATEGORY,
    label: 'Danh mục sản phẩm'
  },
  {
    module: EnumModule.GROUP_PERMISSION,
    label: 'Phân quyền'
  },
  {
    module: EnumModule.AREA,
    label: 'Phân vùng'
  },
  {
    module: EnumModule.ORG_UNIT,
    label: 'Sơ đồ tổ chức'
  },
  {
    module: EnumModule.LOCATION,
    label: 'Vị trí'
  },
  {
    module: EnumModule.USER,
    label: 'Nhân viên'
  }
]
