export const env = {
  api: {
    portal: process.env.REACT_APP_PORTAL_URL_API,
    catalog: process.env.REACT_APP_API_CATALOG_URL
  },
  share_cookie_domain: process.env.REACT_APP_SHARE_COOKIE_DOMAIN,
  web: {
    forlife: process.env.REACT_APP_FORLIFE_HOME
  },
  flag_enable_tester: process.env.REACT_APP_FLAG_ENABLE_TESTER === 'true',
  app_id: process.env.REACT_APP_APP_ID,
  templates: {
    import_product: process.env.REACT_APP_TEMPLATE_IMPORT_PRODUCT_LINK,
    import_color: process.env.REACT_APP_TEMPLATE_IMPORT_COLOR_LINK
  }
}
