import { makeStyles } from '@material-ui/core'
import { FormControl, FormHelperText, TextField } from '@mui/material'
import Autocomplete, { AutocompleteProps, AutocompleteRenderInputParams } from '@mui/material/Autocomplete'
import axios from 'axios'
import { InputLabel } from 'components'
import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { instanceAxios } from 'utils'

interface Props<ItemT>
  extends Omit<AutocompleteProps<ItemT, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput' | 'options' | 'getOptionLabel'> {
  url?: string
  method?: 'get' | 'post'
  label?: string
  placeholder?: string
  err_msg?: string
  require?: boolean
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
  options?: ItemT[]
  getOptionLabel?: (item: ItemT) => string
  selectOption?: (item: ItemT) => void
  selectOptions?: (items: ItemT[]) => void
  filterFunc?: (item: ItemT, searchKey: string) => boolean
}

export function DropdownPicker<ItemT>({
  url,
  placeholder,
  method,
  options,
  value,
  label,
  err_msg,
  require,
  isOptionEqualToValue,
  renderInput,
  getOptionLabel,
  selectOption,
  selectOptions,
  filterFunc,
  disabled,
  ...others
}: Props<ItemT>) {
  const [inputValue, setInputValue] = useState<string>('')
  const [searchKey, setSearchKey] = useState<string>('')
  const [data, setData] = useState<ItemT[]>([])
  const changeSearchKey = debounce(async (val: string) => {
    setSearchKey(val)
  }, 500)

  const fetchData = useCallback(async () => {
    if (!url) {
      return
    }
    // if (searchKey.length === 0) {
    //   setData([])
    //   return
    // }
    const requestParams = {
      search: searchKey
    }
    // TODO: use axiosInstance to handle 401.

    instanceAxios({
      url,
      method,
      ...(method === 'post' ? { data: requestParams } : { params: requestParams })
    })
      .then((response) => {
        // Check if not pagination
        const res = response?.data?.data ?? {}
        setData(res.pagination ? res.data ?? [] : res ?? [])
      })
      .catch((e) => {})
  }, [method, searchKey, url])

  useEffect(() => {
    fetchData()
  }, [fetchData])
  if (label === 'Chi nhánh nhận') console.log('Data: ', label, data, options)

  const renderLabel = useCallback(
    (option: any) => {
      if (option == null) {
        return ''
      }
      return getOptionLabel ? getOptionLabel?.(option) : typeof option === 'string' || option instanceof String ? (option as string) : ''
    },
    [getOptionLabel]
  )

  const classes = useStyles()
  return (
    <FormControl fullWidth>
      {label && <InputLabel label={label} require={require} />}
      <Autocomplete
        disabled={disabled}
        // TODO
        onChange={(_e, v: any) => (Array.isArray(v) ? selectOptions?.(v as ItemT[]) : selectOption?.(v as ItemT))}
        size="small"
        className={classes.select}
        value={value}
        filterOptions={(options) => (filterFunc ? options.filter((option) => filterFunc(option, inputValue)) : options)}
        options={options ? [...options] : [...data]}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        getOptionLabel={(option: string | ItemT) => renderLabel(option)}
        isOptionEqualToValue={isOptionEqualToValue || ((a: any, b: any) => a?.id === b?.id)}
        renderInput={
          renderInput ||
          ((params) => (
            <TextField
              {...params}
              fullWidth
              placeholder={placeholder ?? 'Nhập'}
              onChange={(e) => {
                changeSearchKey(e.target.value)
              }}
            />
          ))
        }
        sx={{
          '& .MuiAutocomplete-tag': {
            backgroundColor: '#FFF'
          }
        }}
        {...others}
      />
      {err_msg && <FormHelperText error>{err_msg}</FormHelperText>}
    </FormControl>
  )
}

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: 'Be Vietnam Pro, sans-serif',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#525252',
    marginBottom: '8px'
  },
  select: {
    '& input': {
      fontSize: '14px'
    },
    '& .MuiInputBase-root': {
      fontFamily: 'Be Vietnam Pro, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
      minHeight: '40px',
      padding: '10px 12px',
      borderRadius: '6px',
      '& fieldset': {
        border: '1px solid #333'
      },
      '&:focus-visible, &:hover': {
        '& fieldset': {
          border: '1px solid #333'
        }
      }
    },
    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: '1px solid #333',
      borderRadius: '6px'
    },
    '& .MuiAutocomplete-tag': {
      borderRadius: 0
    }
  }
}))
