/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { TesterGroup, TesterStateEntity } from 'models'

const initialState: TesterStateEntity = {}

const slice = createSlice({
  name: 'tester',
  initialState,
  reducers: {
    setGroups: (state, { payload }: PayloadAction<TesterGroup[]>) => {
      state.groups = payload
    },
    selectTester: (state, { payload }: PayloadAction<string | undefined>) => {
      state.tester_username = payload
    }
  }
})
export const { actions: testerActions, reducer: testerReducer } = slice
