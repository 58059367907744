import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material'
import { EnumTable } from 'models'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { tableActions } from 'store'
import { useTableConfig, useTableData } from './hook'

export interface TableHeaderEntity {
  id: string
  label: string
}

export interface TableHeaderProps {
  code: EnumTable
}
export function TableHeader({ code }: TableHeaderProps) {
  const { config } = useTableConfig({ code })
  const dispatch = useDispatch()
  const { table, data, all_data } = useTableData(code)
  const columns = config?.columns ?? []

  const has_row_actions = config && config.row_actions && config.row_actions.length > 0

  const ActionCell = () => {
    return (
      <TableCell
        padding="checkbox"
        style={{
          whiteSpace: 'nowrap',
          fontWeight: 'bold',
          textAlign: 'center'
        }}
      >
        Thao tác
      </TableCell>
    )
  }

  return (
    <TableHead>
      <TableRow>
        {config?.select && (
          <TableCell width={80}>
            {config?.select?.header_select && (
              <Checkbox
                checked={table?.select?.select_all ?? false}
                onChange={() => {
                  const type = config?.select?.header_select
                  if (type === 'all_pages') {
                    dispatch(tableActions.selectRows({ items: all_data ?? [], code }))
                  } else if (type === 'current_page') {
                    dispatch(tableActions.selectRows({ items: data ?? [], code }))
                  }
                }}
              />
            )}
          </TableCell>
        )}
        {has_row_actions && config?.row_action_first && <ActionCell />}
        {columns.map(({ header, cell }) => {
          const { label } = header
          return (
            <TableCell
              padding="checkbox"
              style={{
                whiteSpace: 'nowrap',
                fontWeight: 'bold'
              }}
              align={cell.align ?? 'center'}
              sx={{
                minWidth: cell.width ? `${cell.width}px` : '140px'
              }}
            >
              {label}
            </TableCell>
          )
        })}
        {has_row_actions && !config?.row_action_first && <ActionCell />}
      </TableRow>
    </TableHead>
  )
}
