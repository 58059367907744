import { EntityBase } from './entity'

export enum EnumCategoryType {
  BRAND = 'brand',
  GROUP = 'group',
  LINE = 'line',
  STRUCT = 'struct'
}

export const CategoryTypeLabel: Record<EnumCategoryType, string> = {
  [EnumCategoryType.BRAND]: 'Thương hiệu',
  [EnumCategoryType.GROUP]: 'Nhóm',
  [EnumCategoryType.LINE]: 'Dòng',
  [EnumCategoryType.STRUCT]: 'Kết cấu'
}
export interface CategoryEntity extends EntityBase {
  name: string
  parent?: CategoryEntity
  children?: CategoryEntity[]
  type: EnumCategoryType
  slug: string
  product_count?: number
  deleted_at: Date
  order: {
    variant_count: number
    quantity_count: number
    valuable: number
  }
}
