import { AttachmentEntity } from './attachment'
import { EntityBase } from './entity'
import { UserEntity } from './user_portal'

export enum EnumCampaignType {
  Format = 'format',
  Tokyolife = 'tokyolife'
}

export enum EnumCampaignStatus {
  Preview = 'preview',
  Open = 'open',
  Close = 'close'
}

export const CampaignStatusLabel: Record<EnumCampaignStatus, string> = {
  close: 'Đã kết thúc',
  open: 'Đang mở',
  preview: 'Đang thiết lập'
}

export interface CampaignEntity extends EntityBase {
  name: string
  type: EnumCampaignType
  status: EnumCampaignStatus
  guide: string
  description: string
  slug: string
  created_by: UserEntity
  product_files?: AttachmentEntity[]
  created_at: string
}

export const CampaignTypeLabel: Record<EnumCampaignType, string> = {
  [EnumCampaignType.Format]: 'Format',
  [EnumCampaignType.Tokyolife]: 'Tokyolife'
}
