import moment from 'moment'

export const formatDatetime = (d) => {
  if (d) {
    return moment(d).format('DD/MM/YYYY HH:mm:ss')
  }
  return ''
}

export const formatDate = (d) => {
  if (d) {
    return moment(d).format('DD/MM/YYYY')
  }
  return ''
}

export const formatDatetimeEn = (d) => {
  if (d) {
    return moment(d).format('YYYY-MM-DD HH:MM')
  }
  return ''
}

export const formatDateEn = (d, format = 'DD/MM/YYYY') => {
  if (d) {
    return moment(d).format(format)
  }
  return ''
}

export const timeSince = (date) => {
  const seconds = Math.floor((Date.now() - date) / 1000)
  let interval = seconds / 31536000

  if (interval > 1) {
    return `${Math.floor(interval)} năm trước`
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return `${Math.floor(interval)} tháng trước`
  }
  interval = seconds / 86400
  if (interval > 1) {
    return `${Math.floor(interval)} ngày trước`
  }
  interval = seconds / 3600
  if (interval > 1) {
    return `${Math.floor(interval)} giờ trước`
  }
  interval = seconds / 60
  if (interval > 1) {
    return `${Math.floor(interval)} phút trước`
  }
  return `${Math.floor(seconds)} giây trước`
}

export function formatBytes(size: number): string {
  const power = 1024
  let n = 0
  const powerLabels = ['B', 'KB', 'MB', 'GB', 'TB', 'PB']

  while (size >= power && n < powerLabels.length - 1) {
    size /= power
    n += 1
  }

  return `${size.toFixed(2)} ${powerLabels[n]}`
}

export const transformObjToUrlQuery = (data: any) => {
  if (typeof data !== 'object' || data === null) {
    return ''
  }

  const query = Object.entries(data)
    .filter(([, value]) => value !== undefined && value !== null && value !== -1 && value !== '') // Loại bỏ undefined, null và -1
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        // Nếu value là mảng, tạo cặp key=value cho mỗi phần tử
        return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(String(v))}`).join('&')
      }
      // Xử lý trường hợp value không phải mảng
      return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    })
    .join('&')

  return query ? `?${query}` : ''
}
