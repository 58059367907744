/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { EnumProductimportCategory, ImportProductState, ProductImportEntity } from 'models/redux/import_product'
const initialState: ImportProductState = {
  category: EnumProductimportCategory.ALL
}
const slice = createSlice({
  name: 'IMPORT_PRODUCT_STATE',
  initialState,
  reducers: {
    setFile: (state, { payload }: PayloadAction<File | undefined>) => {
      state.file = payload
      if (payload === undefined) {
        state.is_preview = false
      }
    },
    setPreview: (state, { payload }: PayloadAction<boolean>) => {
      state.is_preview = payload
    },
    selectCategory: (state, { payload }: PayloadAction<EnumProductimportCategory>) => {
      state.category = payload
    },
    setData: (state, { payload }: PayloadAction<ProductImportEntity[]>) => {
      state.data = payload
    }
  }
})
export const { actions: importProductActions, reducer: importProductReducer } = slice
