import { ColorEntity } from 'models'

export enum EnumImportCategory {
  ALL = 'all',
  VALID = 'valid',
  INVALID = 'invalid'
}
export interface ImportColorState {
  file?: File
  is_preview?: boolean
  all_data?: ColorEntity[]
  imported_data?: ColorEntity[]
  category?: EnumImportCategory
}
