import { CampaignEntity } from 'models/campaign'
import { CategoryEntity } from 'models/category'
import { OrderEntity } from 'models/order'
import { OrgUnitEntity } from 'models/org_unit'
import { ProductFieldKey } from 'models/product'
import { ApiRequest } from 'services'

export interface CampaignStateEntity {
  campaign?: CampaignEntity
  tab: EnumCampaignTab
  categories?: CategoryEntity[]
  category_id?: string
  in_cart?: boolean
  orders?: OrderEntity[]
  order_unit?: OrgUnitEntity
  order_unit_type?: OrderUnitType
  order_param: Partial<ApiRequest.order.index>
  order_import_file?: File
}

export enum EnumCampaignTab {
  PRODUCTS,
  PRODUCT_IMPORT,
  PRODUCT_HIGHLIGHT,
  ORDERS,
  COLOR_PALETTE
}

export interface ReportState {
  product_fields: ProductFieldKey[]
  include_comments: boolean
  include_overall_order: boolean
  areas: OrgUnitEntity[]
  selected_areas: string[]
  selected_stores: string[]
}

export interface OrderState {
  orders: OrderEntity[]
  file?: File
  order_unit?: OrgUnitEntity
  order_unit_type?: OrderUnitType
}

export type OrderUnitType = 'overall' | 'area' | 'store'
