/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { HighlightProductState, ProductEntity } from 'models'
const initialState: HighlightProductState = {
  data: []
}
const slice = createSlice({
  name: 'IMPORT_COLOR_STATE',
  initialState,
  reducers: {
    setData: (state, { payload }: PayloadAction<ProductEntity[]>) => {
      state.data = payload
    },
    addItem: (state, { payload }: PayloadAction<ProductEntity>) => {
      const index = state.data.findIndex((item) => item.id === payload?.id)
      if (index !== -1) {
        return
      }
      state.data.push({ ...payload })
    },
    removeItem: (state, { payload }: PayloadAction<string>) => {
      state.data = state.data.filter((item) => item.id !== payload)
    }
  }
})
export const { actions: highlightProductActions, reducer: highlightProductReducer } = slice
