import { ListEntity, ProductEntity } from 'models'
import { instanceAxios } from 'utils'
import { ApiRequest } from './interface'
import { CommentEntity } from 'models/comment'

const index = async (params: ApiRequest.comment.index): Promise<ListEntity<CommentEntity>> => instanceAxios.get('/comments', { params })

export default {
  index
}
